import {Component, Input} from '@angular/core';
import {NgClass} from '@angular/common';

@Component({
  selector: 'vw-header-padding-safe-area',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './header-padding-safe-area.component.html',
  styleUrl: './header-padding-safe-area.component.scss'
})
export class HeaderPaddingSafeAreaComponent {
  @Input() look: 'primary' | 'light' | 'white' = 'light'
}
